<template>
  <b-container class="bv-example-row mb-3" fluid>
    <b-card id="printMe">
      <b-row>
        <b-col cols="3">
          <h4>Vardiya Gelir Listesi</h4>
        </b-col>
        <b-col cols="9" class="text-right unPrint">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="info"
              :to="'/vardiyaOzet/' + vardiyaID"
              class="myButton mr-1"
          >
            <feather-icon icon="GridIcon" class="mr-50"/>
            <span class="align-middle">Vardiya Özeti</span>
          </b-button>
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="info"
              class="mr-1 myButton"
              v-print="'#printMe'"
          >
            <feather-icon icon="PrinterIcon" class="mr-50"/>
            <span class="align-middle">Gelir Kayıtlarını Yazdır</span>
          </b-button>
        </b-col>
      </b-row>
      <hr/>
      <b-row class="mt-2">
        <b-col xl="8" lg="12" cols="12" sm="12">
          <table class="table table-striped table-hover">
            <thead class="text-center">
            <tr>
              <th scope="col">REZ NO</th>
              <th scope="col">FİŞ NO</th>
              <th scope="col">DEPARTMAN</th>
              <th scope="col">KAYIT TÜRÜ</th>
              <th scope="col">MÜŞTERİ ADI</th>
              <th scope="col">TUTAR</th>
              <th scope="col">AÇIKLAMA</th>
              <th scope="col">TARİH</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(gelir, index) in this.$store.getters.getDetayliList"
                :key="index"
                class="text-center"
            >
              <td>{{ gelir.rezervasyonNo }}</td>
              <td>{{ gelir.fisNo }}</td>
              <td>
                {{ gelir.departmanAdi }}
              </td>
              <td>
                {{ gelir.odemeTuru }}
              </td>
              <td>{{ gelir.adi }} {{ gelir.soyAdi }}</td>
              <td>{{ formatPrice(gelir.gelir) }} TL</td>
              <td>{{ gelir.aciklama }}</td>
              <td class="nowrap">{{ gelir.kayitTarih }}</td>
            </tr>
            </tbody>
          </table>
        </b-col>
        <b-col xl="3" lg="12" cols="12" sm="12">
          <div class="verticalHr"/>
          <div
              v-for="(departman, index) in gelirListesi.departmanOzeti"
              :key="index"
          >
            <h6>
              {{ departman.departmanAdi }} :
              {{ formatPrice(departman.toplamGelir) }}
            </h6>
          </div>
          <hr/>
          <p>
            Nakit:
            {{
              formatPrice(nakitCiro)
            }}
            TL
          </p>
          <p>
            Kredi Kartı :
            {{
              formatPrice(kkartiCiro)
            }}
            TL
          </p>
          <p>
            Havale :
            {{
              formatPrice(havaleCiro)
            }}
            TL
          </p>
          <p>
            Cari Kredi :
            {{
              formatPrice(krediCiro)
            }}
            TL
          </p>
          <p>
            Online Ciro :
            {{
              onlineCiro == null
                  ? 0
                  : formatPrice(onlineCiro)
            }}
            TL
          </p>
          <small>*Toplam tutara yansımaz</small>
          <hr/>
          <h3>
            Toplam Gelir :
            {{
              formatPrice(toplamCiro)
            }}
            TL
          </h3>
        </b-col>
        <b-col cols="1"/>
      </b-row>
      <hr/>
      <h4 class="mt-2">Vardiya Tahsilat Listesi</h4>
      <b-row class="mt-2">
        <b-col cols="9">
          <table class="table table-striped">
            <thead class="text-center">
            <tr>
              <th scope="col">ÜNVAN</th>
              <th scope="col">İSİM</th>
              <th scope="col">ÖDEME TÜRÜ</th>
              <th scope="col">KULLANICI</th>
              <th scope="col">TUTAR</th>
              <th scope="col">AÇIKLAMA</th>
              <th scope="col">TARİH</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(tah, index) in this.$store.getters.getDetayliTahsilat"
                :key="index"
                class="text-center"
            >
              <td>{{ tah.unvan }}</td>
              <td>{{ tah.isim }}</td>
              <td>{{ tah.tahtur }}</td>
              <td>{{ tah.userName }}</td>
              <td>{{ formatPrice(tah.tutar) }}</td>
              <td>{{ tah.aciklama }}</td>
              <td>{{ tah.kayitTarih }}</td>
            </tr>
            </tbody>
          </table>
        </b-col>
        <b-col cols="3">
          <p>
            Nakit Tahsilat :
            {{
              formatPrice(tahsilatNakit)
            }}
            TL
          </p>
          <p>
            Kredi Kartı Tahsilat :
            {{
              formatPrice(tahsilatKrediKarti)
            }}
            TL
          </p>
          <p>
            Havale Tahsilat :
            {{
              formatPrice(tahsilatHavale)
            }}
            TL
          </p>
          <hr/>
          <h3>
            Toplam Tahsilat :
            {{
              formatPrice(tahsilatlar)
            }}
            TL
          </h3>
        </b-col>
      </b-row>
      <hr/>
      <h4 class="mt-2">Vardiya Gider Listesi</h4>
      <b-row class="mt-2">
        <b-col cols="9">
          <table class="table table-striped">
            <thead class="text-center">
            <tr>
              <th scope="col">TUTAR</th>
              <th scope="col">AÇIKLAMASI</th>
              <th scope="col">TARİH</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(gider, index) in this.$store.getters.getDetayliGider"
                :key="index"
                class="text-center"
            >
              <td>{{ formatPrice(gider.tutar) }} TL</td>
              <td>{{ gider.aciklama }}</td>
              <td>{{ gider.kayitTarih }}</td>
            </tr>
            </tbody>
          </table>
        </b-col>
        <b-col cols="3">
          <h3>
            Toplam Gider :
            {{
              formatPrice(giderler)
            }}
            TL
          </h3>
        </b-col>
      </b-row>
      <hr/>
      <b-row class="justify-content-start">
        <h4 class="ml-1">Vardiya Kasa Durumu</h4>
        <small class="ml-1">* Tahsilat ve Giderler Sonrası Kasa</small>
      </b-row>
      <p>
        Toplam Nakit :
        {{ formatPrice(gelirKayitlariNakitCiro) }} TL
      </p>
      <p>
        Toplam Kredi Kartı :
        {{ formatPrice(kkartiCiro) }} TL
      </p>
      <p>
        Toplam Havale :
        {{ formatPrice(havaleCiro) }} TL
      </p>
      <hr/>
      <h3>
        <b
        >Genel Toplam :
          {{
            formatPrice(kasaTotal)
          }}
          TL</b
        >
      </h3>
    </b-card>
  </b-container>
</template>

<script>
import {
  BRow,
  BContainer,
  BCol,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BFormGroup,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BFormGroup,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BTabs,
    BTab,
  },
  data() {
    return {
      gelirList: [],
      giderList: [],
      tahList: [],
      vardiyaID: this.$route.params.id,
    }
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2)
          .replace('.', ',')
      return val.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
  computed: {
    gelirListesi() {
      if (store.getters.getGelir) {
        return store.getters.getGelir
      }
    },
    nakitCiro() {
      if (store.getters.getGelir) {
        return store.getters.getGelir.gelirKayitlari?.nakitCiro
      }
    },
    kkartiCiro() {
      if (store.getters.getGelir) {
        return store.getters.getGelir.gelirKayitlari?.kkartiCiro
      }
    },
    havaleCiro() {
      if (store.getters.getGelir) {
        return store.getters.getGelir.gelirKayitlari?.havaleCiro
      }
    },
    krediCiro() {
      if (store.getters.getGelir) {
        return store.getters.getGelir.gelirKayitlari?.krediCiro
      }
    },
    onlineCiro() {
      if (store.getters.getGelir) {
        return store.getters.getGelir.gelirKayitlari?.onlineCiro
      }
    },
    toplamCiro() {
      if (store.getters.getGelir) {
        return store.getters.getGelir.gelirKayitlari?.toplamCiro
      }
    },
    tahsilatNakit() {
      if (store.getters.getGelir) {
        return store.getters.getGelir.tahsilatKayitlari?.tahsilatNakit
      }
    },
    tahsilatKrediKarti() {
      if (store.getters.getGelir) {
        return store.getters.getGelir.tahsilatKayitlari?.tahsilatKrediKarti
      }
    },
    tahsilatHavale() {
      if (store.getters.getGelir) {
        return store.getters.getGelir.tahsilatKayitlari?.tahsilatHavale
      }
    },
    tahsilatlar() {
      if (store.getters.getGelir) {
        return store.getters.getGelir.tahsilatKayitlari?.tahsilatlar
      }
    },
    giderler() {
      if (store.getters.getGelir) {
        return store.getters.getGelir.giderKayitlari?.giderler
      }
    },
    gelirKayitlariNakitCiro() {
      if (store.getters.getGelir) {
        return store.getters.getGelir.gelirKayitlari?.nakitCiro + store.getters.getGelir.tahsilatKayitlari?.tahsilatNakit - store.getters.getGelir.giderKayitlari?.giderler
      }
    },
    kasaTotal() {
      if (store.getters.getGelir) {
        const root = store.getters.getGelir?.gelirKayitlari
        return root?.havaleCiro + root?.kkartiCiro + root?.nakitCiro + store.getters.getGelir.tahsilatKayitlari?.tahsilatlar
            - store.getters.getGelir.giderKayitlari?.giderler
      }
    },
  },
  directives: {
    Ripple,
  },
  created() {
    this.$store
        .dispatch('gelirKayitlari', this.vardiyaID)
        .then((res, position = 'bottom-right') => {
          this.gelirList = _.orderBy(res.gelirKayitlari.gelir, 'rezervasyonNo', 'asc')
          this.giderList = _.orderBy(res.giderKayitlari.gider, 'rezervasyonNo', 'asc')
          this.tahList = res.tahsilatKayitlari.tahsilatList
          const mesaj = store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          const mesaj = store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('gelirAction', 'remove')
  },
}
</script>
<style scoped>
[dir] .table thead th {
  border-bottom: 2px solid #ebe9f1;
  padding: 10px !important;
}

[dir] .table th,
[dir] .table td {
  padding: 0 !important;
}

.v-select {
  display: inline-block !important;
  width: 400px;
}

p {
  padding: 0 !important;
  margin: 0 !important;
}

.spinners {
  position: absolute;
  margin-left: 47%;
  margin-top: 15%;
  width: 3rem;
  height: 3rem;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header h4 {
  position: absolute;
  color: black;
  margin-top: 20px;
}

.row {
  justify-content: space-between;
}

.nowrap {
  white-space: nowrap !important;
}

thead tr {
  white-space: nowrap !important;
  text-align: center !important;
  background-color: #f3f2f7 !important;
}

.verticalHr {
  background-color: #ebe9f1;
  height: 100%;
  width: 1px;
  position: absolute;
  transform: translateX(-12px);
}
</style>
<style>

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #f3f2f7 !important;
  color: #6e6b7b !important;
}
</style>
